import React from "react";
// import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";

import { getBlogUrl, toPlainText } from "../../lib/helpers";
import { Link } from "gatsby";
// import PortableText from "./portableText";
import ButtonUnderline from "../Button/ButtonUnderline";

function BlogPostPreview(props) {
  const truncate = (str) => {
    return str.length > 180 ? str.substring(0, 180) + "..." : str;
  };

  return (
    <Link
      to={getBlogUrl(props.slug.current)}
      className="group mb-10 block border-b border-gray-200 pb-12 font-normal text-typography-body no-underline hover:text-typography-body"
    >
      {/* {props.featuredImage && props.featuredImage.asset && (
          <div className="overflow-hidden mb-6">
            <GatsbyImage
              image={props.featuredImage.asset.gatsbyImageData}
              // className="w-full transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
            />
          </div>
        )} */}
      <div className="mb-5 flex flex-wrap items-center font-body text-sm font-bold uppercase tracking-wide text-gray-300 md:flex-nowrap">
        {props.categories.length > 0 && (
          <ul className="flex flex-wrap md:flex-nowrap">
            {props.categories.map((category, i) => (
              <li
                className="font-body text-sm font-bold uppercase tracking-wide text-gray-300"
                key={i}
              >
                {i > 0 ? <>, {category.title}</> : category.title}
              </li>
            ))}
          </ul>
        )}
        {props.categories.length > 0 && props.publishedAt && (
          <div className="mx-1 font-body text-sm font-bold uppercase tracking-wide text-gray-300">
            ·
          </div>
        )}
        {props.publishedAt && (
          <div>
            {differenceInDays(new Date(props.publishedAt), new Date()) > 3
              ? formatDistance(new Date(props.publishedAt), new Date())
              : format(new Date(props.publishedAt), "MMMM d, yyyy")}
          </div>
        )}
      </div>
      <h3 className="mb-4 font-heading text-4xl font-bold uppercase text-typography-heading">
        {props.title}
      </h3>
      {props._rawBody && <p>{truncate(toPlainText(props._rawBody))}</p>}
      <ButtonUnderline
        href={getBlogUrl(props.slug.current)}
        text="Read Article"
      />
    </Link>
  );
}

export default BlogPostPreview;
